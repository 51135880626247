<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
    </div>
    <div v-else>
        <div class="line_up_tab_wrp">
            <div class="line_up_tab-head">
                <span :class="(activeTeamMobile == 1) ? 'active': ''" class="line_up_btn" @click="activeTeamMobile = 1">{{matchLineup.team1.name}}</span>
                <span :class="(activeTeamMobile == 2) ? 'active': ''" class="line_up_btn" @click="activeTeamMobile = 2">{{matchLineup.team2.name}}</span>
            </div>
            <div class="line_up_tab-body" :class="(activeTeamMobile == 1) ? 'team1_active' : 'team2_active'">
                <div  class="line_up_tab cr-global-table">
                    <table>
                        <tbody>
                        <tr v-for="lineup in matchLineup.team1.cricLineups">
                            <td>
                                <span class="player-name">{{lineup.name}} {{(lineup.is_wick && lineup.is_cap) ? '(C †)' : (lineup.is_wick ? '(†)' : (lineup.is_cap ? '(C)' : ''))}}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div  class="line_up_tab cr-global-table">
                    <table>
                        <tbody>
                            <tr v-for="lineup in matchLineup.team2.cricLineups">
                                <td >
                                    <span class="player-name">{{(lineup.is_wick && lineup.is_cap) ? '(C †)' : (lineup.is_wick ? '(†)' : (lineup.is_cap ? '(C)' : ''))}} {{lineup.name}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "line-up",

        data() {
            return {
                matchLineup: null,
                activeTeamMobile: 1,
            }
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_lineup_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_lineup_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_lineup_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        mounted() {
            this.getLineup()
        },
        serverPrefetch() {
            return this.getLineup();
        },
        methods: {
            getLineup() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$MATCH_LINEUP_API.replace(':match', this.$route.params.slug))
                    .then(response => {
                        if (response.data) {
                            this.matchLineup = response.data.result.teams;
                            this.is_data_ready = true;
                        }
                    })
            },
        },

    }
</script>
